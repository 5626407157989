$background-dm: rgb(28 25 23);

.card {
    overflow: hidden;
    position: relative;
    text-decoration: none;
    color: black;
    border-color: rgb(28 25 23);
    border-style: solid;
    border-width: 2px;
    border-radius: 0.375rem;
    text-align: left;
    transition: 0.2s ease-in-out;
    width: 15rem;
    cursor: pointer;

    @media(max-width:800px) {
        width: 100%;
    }
}

.card:active {
    cursor: grabbing;
}

.dark-mode .card {
    color: white;
    border-color: white;
}

.card__content {
    height: 9em;
}

.card__img {
    object-fit: cover;
    height: auto;
    width: 100%;
    top: 0;
    opacity: 1;
    transition: opacity 0.2s ease-in-out;
    user-select: none;
}

.card__info {
    box-sizing: border-box;
    padding: 10px;
    width: 100%;
    border-top: 2px solid $background-dm;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.dark-mode .card__info {
    border-top: 2px solid #fff;
}

.card__info--title {
    font-size: 16px;
    line-height: 1.75rem;
    margin: 0;
    user-select: none;
    font-weight: 600;
}

.card__info--tech {
    display: flex;
    gap: 0.5rem;
    font-size: .875rem;
    line-height: 1.25rem;
    justify-content: flex-start;
    align-items: center;
}

.card__info--tag {
    font-weight: 800;
    padding: 0.25rem 0.5rem;
    border-color: rgb(28 25 23);
    border-width: 2px;
    border-radius: 0.375rem;
    border-style: solid;
    user-select: none;
    font-size: 12px;
}

.dark-mode .card__info--tag {
    border-color: white;
}

.card:hover .card__logo {

    svg {
        transform: scale(1.1);

        @media(max-width:800px) {
            transform: scale(1);
        }
    }
}

.card__logo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    background-color: #fff;
    padding: 30px 30px;
    box-sizing: border-box;

    @media(max-width:800px) {
        padding: 30px 55px;
    }

    svg {
        transition: transform 0.25s;
        transition-timing-function: cubic-bezier(.4, 0, .2, 1);
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}

.dark-mode .card__logo {
    background-color: $background-dm;
}

.dark-mode .card__links .button {
    color: #fff;
    background-color: $background-dm;
}

.dark-mode .card__links .button:hover,
.dark-mode .card__links .button:focus {
    box-shadow: inset 0 0 0 2em #fff;
    color: #000;
}


.card[wobble='1'] {
    -webkit-animation: shake 0.8s;
    animation: shake 0.8s;
}

@keyframes shake {

    30%,
    50%,
    70% {
        transform: translate3d(-2px, 0, 0);
    }

    40%,
    60% {
        transform: translate3d(2px, 0, 0);
    }
}