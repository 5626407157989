@import 'project';
@import 'spotlight';

$text-dm: white;
$background-dm: rgb(28 25 23);

body {
    margin: 0;
    font-family: 'Inter', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
    overflow-y: auto;
}

* {
    box-sizing: border-box;
}

.app {
    text-align: center;
}

.dark-mode {
    color: $text-dm;
    background-color: $background-dm;
}

.app a:hover {
    cursor: pointer;
}

.darkmode {
    position: fixed;
    right: 5rem;
    top: 1rem;
    z-index: 10;
    border-radius: 0.375rem;
    background-color: rgb(196 181 253);
    cursor: pointer;
    border-width: 0;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.4rem;
}

.dark-mode .darkmode {
    background-color: #FDBA74;
    color: $background-dm
}

.darkmode .icon {
    align-items: center;
    display: inline-flex;
    text-decoration: none;
    width: 1.25rem;
    height: 1.25rem;
}

.wrapper {
    margin: auto;
    width: 90%;
}

.intro {
    padding-top: 5rem;
    padding-bottom: 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.intro__h1 {
    font-size: 4.5rem;
    margin: 0;
    margin-bottom: 1rem;
    font-weight: 700;
    user-select: none;
    cursor: pointer;
    line-height: 1;
}

.intro__title {
    font-size: 1.25rem;
    font-weight: 500;
    margin: 0;
    margin-bottom: 0.75rem;
    user-select: none;
}

.intro__paragraph {
    margin: 0;
    font-size: 16px;
    line-height: 1.25rem;
    margin-bottom: 1.5rem;
    max-width: 48rem;
    user-select: none;
    text-align: center;
}

.projects {
    display: flex;
    gap: 1rem;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.timeline {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 5rem;
    margin-bottom: 5rem;
}

.timeline-comp {
    margin-left: -1.5em;
}

.timeline__h1,
.contact__form__h1 {
    text-underline-offset: 8px;
    text-decoration-thickness: 4px;
    text-decoration-line: underline;
    font-size: 1.5rem;
    line-height: 2rem;
    font-weight: 700;
    margin: 0;
    margin-bottom: 1.25rem;
    width: 100%;
}

.timeline-comp div ul li p {
    border-radius: 0.375rem;
    font-weight: 900;
    padding: 0.25rem 0.5rem;
    margin-bottom: 0.25em;
    font-size: .875rem;
}

.dark-mode .timeline-comp div ul li p {
    background-color: white;
    color: $background-dm;
}

.timeline-comp div ul {
    max-width: 100%;
    box-sizing: border-box;
}

.timeline-comp div ul:before {
    background-color: #e5e7eb;
}

.timeline-comp div ul li div {
    padding: 2em 0em 0 2em;
    box-sizing: border-box;
}

.timeline-comp div ul li label {
    background-color: #e5e7eb;
}

.dark-mode .timeline-comp div ul li label {
    background-color: #e5e7eb;
    outline: 10px solid $background-dm;
}

.timeline-comp div ul li h3 {
    font-weight: 600;
    margin-bottom: 0.5rem;
}

.timeline-comp div ul li div div {
    color: rgb(120 113 108);
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5rem;
    padding: 0;
    box-sizing: border-box;
    word-wrap: break-word
}

.dark-mode .timeline-comp div ul li div div {
    color: rgb(168 162 158);
}

.dev-timeline {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 36rem;
    text-align: left;
    box-sizing: border-box;
    text-overflow: clip;
    user-select: none;
}

.contact {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2.5rem;
}

.contact__form {
    width: 36rem;
    display: flex;
    flex-direction: column;
}

.contact__form input:focus,
.contact__form textarea:focus {
    outline: none;
}

.contact__form__h1 {
    width: 100%;
    text-align: left;
    user-select: none;
}

.contact__form__input {
    padding: 0.5rem;
    border-width: 2px;
    border-radius: 0.375rem;
    border-color: #e5e7eb;
    background-color: transparent;
    border-style: solid;
    font-size: 100%;
    font-family: inherit;
    font-weight: inherit;
    line-height: inherit;
    color: inherit;
}

.middle-input {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.contact__textarea {
    margin-bottom: 1rem;
}


.contact__btn {
    background-color: #cf245f;
    background-image: linear-gradient(to bottom right, #fcd34d, #ef4444, #ec4899);
    border: 0;
    border-radius: .35rem;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    font-family: inherit;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.5rem;
    /* 28px */
    padding: 0.75rem 2rem;
    text-align: center;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    width: max-content;

    @media(max-width:800px) {
        margin: auto;
    }
}

.contact__btn:hover {
    animation: btn-shake 0.3s infinite ease-in-out;
}

@keyframes btn-shake {

    50% {
        transform: translate3d(-2px, 0, 0) rotate(-1deg);
    }

    50% {
        transform: translate3d(2px, 0, 0) rotate(1deg);
    }
}

.footer {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    text-align: center;
}

.footer__icons {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    justify-content: center;
}

.footer__copyright {
    opacity: .5;
    font-size: .875rem;
    line-height: 1.25rem;
    margin: 0;
    margin-top: 0.5rem;
    user-select: none;
}

.footer__icon {
    fill: white;
}

.footer__link {
    position: relative;
    color: rgb(255 255 255);
    background-color: #a8a29e;
    border-radius: 9999px;
    align-items: center;
    display: inline-flex;
    padding: 0.5rem;
    text-decoration: none;
    font-size: 1.25rem;
    z-index: 7;
    transition: all .3s;
}

.footer__link:hover {
    color: #a8a29e;
}

.footer__link::before {
    position: absolute;
    z-index: 9;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    content: '';
    background: #fff;
    border-radius: 10px;
    transition: transform .3s;
}

.dark-mode .footer__link::before {
    background: #1D1917;
}

.footer__link::before {
    transform-origin: left top;
    transform: scale(1, 0);
}

.footer__link:hover::before {
    transform-origin: left bottom;
    transform: scale(1, 1);
}

@media only screen and (max-width: 800px) {
    .darkmode {
        right: 1rem;
    }
}

.shine[click='1'] {
    background: linear-gradient(to right,
            #000 20%,
            #FDBA74 30%,
            #FDBA74 70%,
            #000 80%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    text-fill-color: transparent;
    background-size: 500% auto;
    animation: textShine 1s ease-in-out;
}

.dark-mode .shine[click='1'] {
    background: linear-gradient(to right,
            #fff 20%,
            #C3B5FD 30%,
            #C3B5FD 70%,
            #fff 80%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    text-fill-color: transparent;
    background-size: 500% auto;
    animation: textShine 1s ease-in-out;
}

@keyframes textShine {
    0% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}

.emote {
    margin-left: 8px !important;
    position: absolute !important;
    transition: all 1s;
    cursor: pointer;
}

.emote[click='1'] {
    animation: spin 0.5s ease-in-out;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.zoomed-in {
    animation: zoom-in 0.4s;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
    // transform: scale(1.6);
}

.zoomed-out {
    animation: zoom-out 0.4s;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
    // transform: scale(1);
}

@keyframes zoom-in {
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(1.6);
    }
}

@keyframes zoom-out {
    0% {
        transform: scale(1.6);
    }

    100% {
        transform: scale(1);
    }
}

.btn {
    text-transform: uppercase;
    font-family: inherit;
    font-weight: bold;
    border: none;
    padding: 8px 15px;
    font-size: 18px;
    border-radius: 3px;
    cursor: pointer;
    transition: all 0.5s ease-in-out;
    background-color: $background-dm;
    color: #fff;
    display: flex;
    gap: 10px;
    width: fit-content;
    text-wrap: nowrap;
    align-items: center;

    svg {
        fill: #fff;
        height: 20px;
    }
}